import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/search-info/search-info.css"

@customElement('sbk-search-info')
export class SbkSearchInfo extends LitElement {
    createRenderRoot() {
        return this;
    }
}
