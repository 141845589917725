import {customElement} from 'lit/decorators.js';
import {LitElement} from "lit";
import './language-switch-tracking.scss'

// eslint-disable-next-line
declare let _paq: any

@customElement('sbk-language-switch-tracking')
export class LanguageSwitchTracking extends LitElement {

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.track)
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('click', this.track)
    }

    track() {
        const title = document.querySelector('title')?.text
        _paq.push(['trackEvent', 'English', title])
    }

    protected createRenderRoot() {
        return this;
    }
}
