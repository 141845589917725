import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/form/form.scss"
import {query} from "lit/decorators/query.js";
import {SbkRadio} from "@/components/radio/radio.ts";
import {SbkForm} from "@/components/form/form.ts";

@customElement('sbk-pkv-survey')
export class SbkPkvSurvey extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('.pkv-survey__wrapper')
    _wrapper!: HTMLDivElement

    connectedCallback() {
        super.connectedCallback();
        this._form.addEventListener('submit', (e) => {
            e.preventDefault()
            this._onSubmit();
        });
    }

    protected createRenderRoot() {
        return this
    }

    private _onSubmit() {
        const invalidElements = SbkForm.validate(this._form);
        if (invalidElements.length) {
            const firstInvalidElement = invalidElements[0];
            firstInvalidElement.focus({preventScroll: true});
            firstInvalidElement.scrollIntoView({behavior: 'smooth'});
        } else {
            const question = this._form.dataset.question;
            const radioButton: SbkRadio|null = this._form.querySelector('sbk-radio')
            let inputValue = '';
            for (const entry of new FormData(this._form).entries()) {
                if (entry[0] == radioButton?.name) {
                    inputValue = entry[1].toString();
                }
            }
            fetch(`/?type=7100&tx_mmssite_wechselpkv%5Bquestion%5D=${question}&tx_mmssite_wechselpkv%5Banswer%5D=${inputValue}`, {
                method: this._form.method,
                body: new FormData(this._form),
            })
                .then((response) => {
                    return response.text()
                })
                .then((data) => {
                    if (data === 'contact') {
                        this._wrapper.innerHTML = ''
                        const hiddenForm = this.querySelector('.pkv-survey__hiddenForm ')
                        if (hiddenForm) {
                            hiddenForm.classList.remove('hidden')
                        }
                    } else {
                        this._wrapper.innerHTML = data;
                        this._form.addEventListener('submit', (e) => {
                            e.preventDefault()
                            this._onSubmit();
                        });
                    }
                })
        }
    }
}
