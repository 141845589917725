import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {SbkInput} from "@/components/input/input";
import {query} from "lit/decorators/query.js";
import {SbkForm} from "@/components/form/form";
import "@/components/helpful-survey/helpful-survey.scss";
import {SbkButton} from "@/components/button/button.ts";

@customElement('sbk-helpful-survey')
export class SbkHelpfulSurvey extends LitElement {

    @property({type: Number, attribute: 'form-type-no'})
    formTypeNo: number = 0;

    @property({type: Number, attribute: 'form-type-yes'})
    formTypeYes: number = 0;

    @property({attribute: 'heading-no'})
    headingNo: string = '';

    @property({attribute: 'heading-yes'})
    headingYes: string = '';

    @property({attribute: 'label-no'})
    labelNo: string = '';

    @property({attribute: 'label-yes'})
    labelYes: string = '';

    @property({type: Number, attribute: 'title-uid-no'})
    titleUidNo: number = 0;

    @property({type: Number, attribute: 'title-uid-yes'})
    titleUidYes: number = 0;

    @property()
    question: string = '';

    @query('form')
    _form!: HTMLFormElement

    @query('sbk-form')
    _sbkForm!: SbkForm;

    @query('#surveyModalYesContent')
    _surveyModalYesContent!: HTMLDivElement;

    @query('#surveyModalNoContent')
    _surveyModalNoContent!: HTMLDivElement;

    @query('sbk-form sbk-button')
    _submitButton!: SbkButton


    connectedCallback() {
        super.connectedCallback();
    }

    render() {
        return html`
            <div class="element-container">
                <sbk-banner variant="muted">
                    <p class="text-bold">${this.question}</p>
                    <div class="helpful-survey__button_wrapper">
                        <sbk-modal @modal-open=${this._onModalOpenYes} heading=${this.headingYes}>
                            <sbk-button slot="triggerElement" variant="secondary">${this.labelYes}</sbk-button>
                            <div id="surveyModalYesContent"></div>
                        </sbk-modal>
                        <sbk-modal @modal-open=${this._onModalOpenNo} heading=${this.headingNo}>
                            <sbk-button slot="triggerElement" variant="secondary">${this.labelNo}</sbk-button>
                            <div id="surveyModalNoContent"></div>
                        </sbk-modal>
                    </div>
                </sbk-banner>
            </div>
        `;
    }

    _onModalOpenYes() {
        this._onModalOpen(this.formTypeYes, this.titleUidYes, this._surveyModalYesContent);
    }

    _onModalOpenNo() {
        this._onModalOpen(this.formTypeNo, this.titleUidNo, this._surveyModalNoContent);
    }

    _onModalOpen(formType: number, titleUid: number, surveyModalContent: HTMLDivElement) {

        const url = "?type="
            + formType
            + "&noforce=1&tx_powermail_pi1[uid"
            + titleUid
            + "]="
            + encodeURIComponent( document.title );

        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                this._addContent(data, surveyModalContent)
            })
    }

    _addContent(data: string, surveyModalContent: HTMLDivElement) {
        surveyModalContent.innerHTML = data;
        this._form.removeEventListener('submit', this._sbkForm._onSubmit)
        this._form.addEventListener('submit', (e: Event) => {
            e.preventDefault();
            this._onSubmit(surveyModalContent);
        });
    }

    protected createRenderRoot() {
        return this;
    }

    private _onSubmit(surveyModalContent: HTMLDivElement) {
        let invalidElements: HTMLElement[] = [];
        const inputs = this._form.querySelectorAll('sbk-input')
        for (let i = 0; i < inputs.length; i++) {
            const element = inputs[i];
            if (element instanceof SbkInput) {
                const validators = element.getValidators();
                element.handleErrors(validators, element.value ?? '');
                if (element.invalid) {
                    invalidElements = [...invalidElements, element];
                }
            }
        }
        if (invalidElements.length) {
            const firstInvalidElement = invalidElements[0];
            firstInvalidElement.focus({preventScroll: true});
            firstInvalidElement.scrollIntoView({behavior: 'smooth'});
        } else {
            const surveyModal = <HTMLElement> surveyModalContent.querySelector('#surveyModal')
            const formPage = surveyModal.dataset.surveyFormPage;
            let url = formPage + "?" + this._form.action.split("?")[1]
            url = url.split("&cHash")[0]
            const formData = new FormData(this._form);
            formData.append(this._submitButton.name ?? '', this._submitButton.value ?? '');
            fetch(url, {
                method: this._form.method,
                body: formData,
            })
                .then((response) => {
                    return response.text()
                })
                .then((data) => {
                    surveyModalContent.innerHTML = data;
                    this._form.addEventListener('submit', (e) => {
                        e.preventDefault()
                        this._onSubmit(surveyModalContent);
                    });
                })
        }
    }
}
