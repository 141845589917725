import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {SbkInput} from "@/components/input/input";
import "@/components/form/form.scss"
import {query} from "lit/decorators/query.js";
import {SbkButton} from "@/components/button/button";
import "@/components/physician-search/physician-search.scss"
import {SbkPagination} from "@/components/pagination/pagination";

@customElement('sbk-physician-search')
export class SbkPhysicianSearch extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('sbk-pagination')
    _pagination!: SbkPagination

    @query('.physician-results')
    _resultContainer!: HTMLDivElement

    @query('.physician-results__heading')
    _resultHeading!: HTMLElement

    @query('sbk-button')
    _submitButton!: SbkButton


    connectedCallback() {
        super.connectedCallback();
        this._form.addEventListener('submit', (e) => {
            e.preventDefault()
            this._onSubmit();
        });
        this._pagination && this._pagination.addEventListener('page-change', (e) => {
            e.preventDefault()
            const href = (<CustomEvent> e).detail.value;
            this._handlePageChange(href);
        });
        if (this._resultContainer) {
            this._resultHeading.focus({preventScroll: true});
            this._resultHeading.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }

    protected createRenderRoot() {
        return this
    }

    private _onSubmit() {
        let invalidElements: HTMLElement[] = [];
        const inputs = this._form.querySelectorAll('sbk-input')
        for (let i = 0; i < inputs.length; i++) {
            const element = inputs[i];
            if (element instanceof SbkInput) {
                const validators = element.getValidators();
                element.handleErrors(validators, element.value ?? '');
                if (element.invalid) {
                    invalidElements = [...invalidElements, element];
                }
            }
        }
        if (invalidElements.length) {
            const firstInvalidElement = invalidElements[0];
            firstInvalidElement.focus({preventScroll: true});
            firstInvalidElement.scrollIntoView({behavior: 'smooth'});
        } else {
            fetch(this._form.action, {
                method: this._form.method,
                body: new FormData(this._form),
            })
                .then((response) => {
                    return response.text()
                })
                .then((data) => {
                    this._replace(data)
                })
        }
    }

    private _handlePageChange(href: string) {
        fetch(href, {})
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                this._replace(data)
            })
    }

    private _replace(data: string) {
        this.outerHTML = data
    }
}
