import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/consultant-search/consultant-search.scss"

@customElement('sbk-consultant-search')
export class SbkConsultantSearch extends LitElement {
    createRenderRoot() {
        return this;
    }
}
