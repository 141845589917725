import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/form/form.scss"
import {query} from "lit/decorators/query.js";
import {SbkButton} from "@/components/button/button";
import {Banner} from "@/components/banner/banner";
import "@/components/bmi-calculator/bmi-calculator.css"
import {SbkForm} from "@/components/form/form.ts";

@customElement('sbk-bmi-calculator')
export class SbkBmiCalculator extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('sbk-banner')
    _resultContainer!: Banner

    @query('sbk-button')
    _submitButton!: SbkButton


    connectedCallback() {
        super.connectedCallback();
        this._form.addEventListener('submit', (e) => {
            e.preventDefault()
            this._onSubmit();
        });
    }

    protected createRenderRoot() {
        return this
    }

    private _onSubmit() {
        const invalidElements = SbkForm.validate(this._form);
        if (invalidElements.length) {
            const firstInvalidElement = invalidElements[0];
            firstInvalidElement.focus({preventScroll: true});
            firstInvalidElement.scrollIntoView({behavior: 'smooth'});
        } else {
            fetch(this._form.action, {
                method: this._form.method,
                body: new FormData(this._form),
            })
                .then((response) => {
                    return response.text()
                })
                .then((data) => {
                    this.innerHTML = data;
                    this._submitButton.disabled = false;
                    this._resultContainer.scrollIntoView({
                        behavior: 'smooth'
                    })
                    this._form.addEventListener('submit', (e) => {
                        e.preventDefault()
                        this._onSubmit();
                    });
                })
        }
    }
}
