import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/a-z-list/a-z-list.scss"

@customElement('sbk-a-z-list')
export class SbkAZList extends LitElement {
    protected createRenderRoot() {
        return this;
    }
}
