import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/office-search/office-search.scss"

@customElement('sbk-office-search')
export class SbkOfficeSearch extends LitElement {
    createRenderRoot() {
        return this;
    }
}
