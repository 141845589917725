import { customElement } from 'lit/decorators.js';
import { html, LitElement } from "lit";
import { badgeStyle } from './badge.style';


@customElement('sbk-badge')
export class SbkBadge extends LitElement {


    static get styles() {
        return badgeStyle;
    }

    render() {
        return html`
            <span>
                <slot></slot>
            </span>
        `;
    }
}
