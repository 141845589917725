import {LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {query} from "lit/decorators/query.js";
import {SbkRadio} from "@/components/radio/radio.ts";
import {SbkRadioGroup} from "@/components/radio-group/radio-group.ts";

@customElement('sbk-voluntarily-insured-calculator')
export class SbkVoluntarilyInsuredCalculator extends LitElement {

    @query('#severanceAsSinglePaymentGroup')
    _severanceAsSinglePaymentRadioGroup!: SbkRadioGroup

    @query('#spousePrivateInsuredGroup')
    _spousePrivateInsuredRadioGroup!: SbkRadioGroup

    @property({attribute: 'error-message-severance-as-single-payment'})
    errorMessageSeveranceAsSinglePayment = '';

    @property({attribute: 'error-message-spouse-private-insured'})
    errorMessageSpousePrivateInsured = '';


    connectedCallback() {
        super.connectedCallback();
        this._severanceAsSinglePaymentRadioGroup.addEventListener('radio-group-change', this._onSeveranceAsSinglePaymentRadioGroupChange)
        this._spousePrivateInsuredRadioGroup.addEventListener('radio-group-change', this._onSpousePrivateInsuredRadioGroupChange)
    }

    protected createRenderRoot() {
        return this
    }

    _onSeveranceAsSinglePaymentRadioGroupChange = () => {
        const checkedRadio = this._severanceAsSinglePaymentRadioGroup.querySelector('sbk-radio[checked]');
        if (checkedRadio && checkedRadio instanceof SbkRadio) {
            if (checkedRadio.value === '1') {
                this._severanceAsSinglePaymentRadioGroup.invalid = true;
                this._severanceAsSinglePaymentRadioGroup.annotation = this.errorMessageSeveranceAsSinglePayment;
                this._severanceAsSinglePaymentRadioGroup._radioButtons.forEach((radioButton: SbkRadio) => {
                    radioButton.invalid = true;
                })
            } else {
                this._severanceAsSinglePaymentRadioGroup.invalid = false;
                this._severanceAsSinglePaymentRadioGroup.annotation = '';
                this._severanceAsSinglePaymentRadioGroup._radioButtons.forEach((radioButton: SbkRadio) => {
                    radioButton.invalid = false;
                })
            }
        }
    }

    _onSpousePrivateInsuredRadioGroupChange = () => {
        const checkedRadio = this._spousePrivateInsuredRadioGroup.querySelector('sbk-radio[checked]');
        if (checkedRadio && checkedRadio instanceof SbkRadio) {
            if (checkedRadio.value === '1') {
                this._spousePrivateInsuredRadioGroup.invalid = true;
                this._spousePrivateInsuredRadioGroup.annotation = this.errorMessageSeveranceAsSinglePayment;
                this._spousePrivateInsuredRadioGroup._radioButtons.forEach((radioButton: SbkRadio) => {
                    radioButton.invalid = true;
                })
            } else {
                this._spousePrivateInsuredRadioGroup.invalid = false;
                this._spousePrivateInsuredRadioGroup.annotation = '';
                this._spousePrivateInsuredRadioGroup._radioButtons.forEach((radioButton: SbkRadio) => {
                    radioButton.invalid = false;
                })
            }
        }
    }
}
