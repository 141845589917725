import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {query} from "lit/decorators/query.js";
import '@/components/icd-search/icd-search.scss'
import {SbkButton} from "@/components/button/button";

@customElement('sbk-icd-search')
export class SbkIcdSearch extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('.icd-search-result')
    _resultContainer!: HTMLDivElement

    @query('sbk-button')
    _submitButton!: SbkButton

    connectedCallback() {
        super.connectedCallback();
        this._form.addEventListener('submit', (e) => {
            e.preventDefault()
            this._onSubmit();
        });
        this._resultContainer && this._resultContainer.focus();
    }

    protected createRenderRoot() {
        return this
    }

    private _onSubmit() {
        fetch(this._form.action, {
            method: this._form.method,
            body: new FormData(this._form),
        })
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                this._replace(data)
            })
    }

    private _replace(data: string) {
        this.outerHTML = data
    }
}
