import {LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {inputStyles} from "@/components/input/input.styles.ts";
import {RequiredValidator, Validator} from "@/components/validators/validators.ts";
import {AutocompleteMixin, SuggestItem} from "@/components/autocomplete/autocomplete-mixin.ts";
import {autocompleteStyles} from "@/components/autocomplete/autocomplete.styles.ts";
import {labelStyles} from "@/components/label/label.styles";
import {autocompleteIcdStyles} from "@/components/autocomplete-input-icd/autocomplete-input-icd.styles";


@customElement('sbk-autocomplete-input-icd')
export class SbkAutocompleteInputIcd extends AutocompleteMixin(LitElement) {

    validatorMapper: {
        [key: string]: Validator
    } = {
        'required': RequiredValidator,
    }

    @property()
    url = ''

    static get styles() {
        return [
            autocompleteStyles,
            labelStyles,
            inputStyles,
            autocompleteIcdStyles
        ];
    }

    async _querySuggestions(inputValue: string) {
        if (inputValue.length < 1) {
            this.suggestions = [];
            this.close(true)
            return;
        }

        await fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `tx_mmsicd_icdsearch[code]=${inputValue}`
        })
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                const parsedSuggestions = JSON.parse(data);
                const allSuggestions: SuggestItem[] = [];
                Object.entries(parsedSuggestions).forEach((suggestion) => {
                    allSuggestions.push({
                        label: <string>suggestion[1],
                        value: <string>suggestion[1]
                    });
                });
                this.suggestions = allSuggestions;
            })
        await this.updateComplete;
    }
}
