import {customElement} from 'lit/decorators.js';
import {LitElement} from "lit";

// eslint-disable-next-line
declare let _paq: any

@customElement('sbk-search-teaser-tracking')
export class SearchTeaserTracking extends LitElement {

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.track)
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('click', this.track)
    }

    track() {
        const headline = document.querySelector('sbk-headline-link')
        const text = headline?.innerHTML.trim()
        _paq.push(['trackEvent', 'Suche', 'SERP Such-Teaser: ' + text])
    }

    protected createRenderRoot() {
        return this;
    }
}
