import {css} from 'lit';

export const badgeStyle = css`

    :host {
        display: inline-block;
        height: var(--sbkBadgeSizingMaxHeight);
        min-width: var(--sbkBadgeSizingMinWidthText);
        padding: var(--sbkBadgeSpacingInsetTop) var(--sbkBadgeSpacingInsetRight) var(--sbkBadgeSpacingInsetBottom) var(--sbkBadgeSpacingInsetLeft);
        background-color: var(--sbkBadgeColorBgColorOnLightDefault);
        color: var(--sbkBadgeColorFgColorOnLightEnabled);
        font: var(--sbkBadgeTypographyLabel);
        border-radius: var(--sbkBadgeBorderRadiusDefault);
    }
`
